import React, { memo } from 'react';
import IBadge from '@/interfaces/IBadge';
import { Avatar, Col, Popover, Row, Typography } from 'antd';
import getTranlation from '@/helpers/getTranslation';

interface IBadgesList {
  badges: IBadge[];
  label?: string;
}

const BadgesList = memo(({
  badges,
  label,
}: IBadgesList) => {
  if (badges.length <= 0) return null;

  return (
    <Row gutter={10}>
      {
        label ? (
          <Col span={24}>
            <Typography.Title level={4}>
              {label}
            </Typography.Title>
          </Col>
        ) : null
      }
      {
        badges.map(({ id, url, label }) => (
          <Col key={id}>
            <Popover
              key={id} 
              content={getTranlation(label)}
            >
              <Avatar 
                key={id} 
                src={url} 
                alt={getTranlation(label)} 
              />
            </Popover>
          </Col>
        ))
      }
    </Row>
  );
});

export default BadgesList;
